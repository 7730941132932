import { CloudshelfInfo, getCloudshelfInfo } from '../../hooks/UseCloudshelfInfo';
import {
    CheckoutExperience,
    CloudshelfPayloadStatus,
    CloudshelfPayloadType,
    ContentType,
    EngineType,
    ImageAnchor,
    KeyValuePair as KeyValuePair2,
    KnownPlugin,
    TileSize,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { DeviceInfo } from './DeviceService';
import { CheckoutFlow, CloudshelfEngineConfig, DisplayOnlyEngineConfig } from './types/config/CloudshelfEngineConfig';
import { EmptyConfig, EmptyDisplayOnlyConfig } from './EmptyConfig';
import * as Sentry from '@sentry/react';
import { TileConfig } from './types/config/TileConfig';
import { Category } from '../CategoryService/entities/Category';
import { KeyValuePair } from './types/config/KeyValuePair';
import { FilterableProduct, FilterableProductVariant } from '../ProductServices/FilterableProductTypes';
import { dexieDatabase } from '../StorageService/DexieDatabase';
import {
    ConfigWorkerChangedConfigPayload,
    ConfigWorkerSetupPreviewOptions,
} from '../../workers/config/Config.Worker.Types';
import { decodeBase64StringIfNeeded } from '../../utils/String.Util';
import { configWorker } from '../../workers/config/Config.Worker.Instance';
import { StorageService } from '../StorageService/StorageService';
import { StorageKey } from '../StorageService/StorageKeys.enum';
import { CloudshelfBridge } from '../../utils/CloudshelfBridge.Utils';

export class ConfigurationService {
    private _status: CloudshelfPayloadStatus | undefined = undefined;
    private _config: CloudshelfEngineConfig | undefined = undefined;
    private _providerConfig: KeyValuePair[] = [];
    private _latestMessage: string | undefined = undefined;
    private _isDevice: boolean | undefined;
    private _deviceNeedsPairing: boolean | undefined;

    private _configUpdatedSubject: Subject<void> = new Subject<void>();
    private _productCustomizerPriceModifierVariant: FilterableProductVariant | undefined;
    private _productCustomizerPriceModifierProduct: FilterableProduct | undefined;

    constructor(private readonly _storageService: StorageService) {}

    public async setup(localDeviceInfo?: DeviceInfo): Promise<CloudshelfInfo> {
        const cloudshelfInfo = getCloudshelfInfo(localDeviceInfo);
        this._isDevice = cloudshelfInfo.payloadType === CloudshelfPayloadType.Device;
        this._deviceNeedsPairing = cloudshelfInfo.deviceNeedsPairing;

        const parsedQuery = new URLSearchParams(window.location.search);
        let payloadId = decodeBase64StringIfNeeded(cloudshelfInfo.cloudshelfOrDeviceId);
        if (payloadId.trim() === '') {
            //we need to force an ID because the backend function will fail validation if we don't
            //this is hacky and I don't like it... but time constraints and all that...
            //so we force an id we know is never going to exist (in the past)
            if (cloudshelfInfo.payloadType === CloudshelfPayloadType.Device) {
                payloadId = 'gid://cloudshelf/device/01H5SYS9AAAAAAAAAAAAAAAAAA';
            } else if (cloudshelfInfo.payloadType === CloudshelfPayloadType.Preview) {
                payloadId = 'gid://cloudshelf/cloudshelf/01H5SYS9AAAAAAAAAAAAAAAAAA';
            }
        }

        let previewOnlyOptions: ConfigWorkerSetupPreviewOptions | null = null;
        if (cloudshelfInfo.payloadType === CloudshelfPayloadType.Preview) {
            previewOnlyOptions = {
                forcedScreenSize: 15,
            };

            const forcedScreenSizeStr = parsedQuery.get('forceScreenSize');
            if (forcedScreenSizeStr) {
                let forcedScreenSize: number | null = parseFloat(forcedScreenSizeStr);
                if (isNaN(forcedScreenSize)) {
                    forcedScreenSize = null;
                }

                if (forcedScreenSize !== null) {
                    previewOnlyOptions.forcedScreenSize = forcedScreenSize;
                }
                this._storageService.put(StorageKey.STORED_PREVIEW_SCREENSIZE, forcedScreenSizeStr);
            } else {
                const storedSize = this._storageService.get(StorageKey.STORED_PREVIEW_SCREENSIZE) ?? null;

                if (storedSize !== null) {
                    let forcedScreenSize: number | null = parseFloat(storedSize);
                    if (isNaN(forcedScreenSize)) {
                        forcedScreenSize = null;
                    } else {
                        previewOnlyOptions.forcedScreenSize = forcedScreenSize;
                    }
                }
            }
        }

        await dexieDatabase().deleteConfigReloadBlockedReason();
        await configWorker().setup(
            {
                payloadId,
                payloadType: cloudshelfInfo.payloadType,
                reportPageLoad: true,
                engineVersion: process.env.REACT_APP_PACKAGE_VERSION ?? 'unknown',
                windowSize: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
                previewOnly: previewOnlyOptions,
            },
            async (message: ConfigWorkerChangedConfigPayload) => {
                console.info('[Configuration Service (Main Thread)] Recieved Changed Config Payload', message);
                this._config = message.config;
                this._providerConfig = message.providerConfig;
                this._latestMessage = message.latestMessage;
                this._status = message.status;

                if (message.config) {
                    Sentry.setUser({
                        username: message.config.ownerName,
                    });
                    console.info(`[Configuration Service (Main Thread)] Set Sentry Username`, message.config.ownerName);
                }

                (window as any).CLOUDSHELF_CONFIG = message.config;
                (window as any).PROVIDER_CONFIG = message.providerConfig;
                (window as any).LATEST_CONFIG_MESSAGE = message.latestMessage;

                if (CloudshelfBridge.isAvailable() && CloudshelfBridge.getVersion() >= 1) {
                    // provide the RFID settings
                    console.log(
                        '[Configuration Service (Main Thread)] Providing rfidGS1CompanyPrefixes to Cloudshelf Bridge',
                    );
                    CloudshelfBridge.nordicIdProvideSettings(this._config?.rfidGS1CompanyPrefixes ?? [], false);
                }

                console.log('[Configuration Service (Main Thread)] Reporting change to all subscriptions...');
                this._configUpdatedSubject.next();
            },
        );

        return cloudshelfInfo;
    }

    public getContentRequestType(): CloudshelfPayloadType {
        const info = getCloudshelfInfo();

        return info.payloadType;
    }

    public setReloadConfigBlockedReason(reason: string | undefined) {
        if (reason) {
            dexieDatabase().putConfigReloadBlockedReason(reason);
        } else {
            dexieDatabase().deleteConfigReloadBlockedReason();
        }
    }

    public isDevice(): boolean {
        return this._isDevice ?? false;
    }

    public isInternalDevice(): boolean {
        if (!this._isDevice) {
            return false;
        }

        return this._config?.device?.isCloudshelfInternalDevice ?? false;
    }

    public deviceNeedsPairing(): boolean {
        return this._deviceNeedsPairing ?? false;
    }

    public config(): CloudshelfEngineConfig | undefined {
        return this._config;
    }

    public providerConfig(): KeyValuePair[] {
        return this._providerConfig;
    }

    public status(): CloudshelfPayloadStatus | undefined {
        return this._status;
    }

    public get isUsingCachedConfig(): boolean {
        return this._status === CloudshelfPayloadStatus.Cached;
    }

    public observe(): Observable<void> {
        return this._configUpdatedSubject.asObservable();
    }

    public get cloudshelfId(): string | undefined {
        return this._config?.id;
    }

    public get categories(): Category[] {
        return _.compact(
            _.map(this._config?.tiles, tile => {
                if (tile.type !== ContentType.ProductGroup || !tile.collectionStorefrontId) {
                    return null;
                } else {
                    return {
                        id: tile.collectionStorefrontId,
                        internalId: tile.collectionId ?? '',
                        handle: tile.handle ?? '',
                        image: tile.backgroundImage,
                        title: tile.title,
                        isInternalAllCategory: tile.isAllCollectionTile ?? false,
                        useImageOverride: tile.useImage ?? false,
                        productGroupIsUpsellContent: tile.productGroupIsUpsellContent ?? false,
                    };
                }
            }),
        );
    }

    public get powerTiles(): TileConfig[] {
        return _.compact(
            _.map(this._config?.tiles ?? [], tile => {
                if (tile.configurationIssues.length === 0) {
                    return tile;
                } else {
                    return null;
                }
            }),
        );
    }

    public get shouldUseProductAnimations(): boolean {
        return this._config?.theme?.useProductAnimations ?? false;
    }

    public get imageAnchor(): ImageAnchor {
        return this._config?.theme.imageAnchor ?? ImageAnchor.None;
    }

    public get displayOnlyConfig(): DisplayOnlyEngineConfig {
        return this._config?.displayOnly ?? EmptyDisplayOnlyConfig;
    }
    public get deviceMode(): EngineType {
        return this._config?.deviceMode ?? EngineType.Interactive;
    }

    public get isInPreviewMode(): boolean {
        return this.status() === CloudshelfPayloadStatus.CloudshelfPreview;
    }

    public setProductCustomiserPriceModifierProduct(product: FilterableProduct | undefined): void {
        this._productCustomizerPriceModifierProduct = product;
    }

    public get productCustomiserPriceModifierProduct(): FilterableProduct | undefined {
        return this._productCustomizerPriceModifierProduct;
    }

    public setProductCustomiserPriceModifierVariant(variant: FilterableProductVariant | undefined): void {
        this._productCustomizerPriceModifierVariant = variant;
    }

    public get productCustomiserPriceModifierVariant(): FilterableProductVariant | undefined {
        return this._productCustomizerPriceModifierVariant;
    }

    public get upsellCategory(): Category | undefined {
        //this is a temp thing, it should come from the API.

        const cat = this.categories.find(c => c.productGroupIsUpsellContent);
        return cat;
    }

    public get defaultTileSize(): TileSize {
        return this._config?.theme.tileSize ?? TileSize.Square;
    }

    public get mixedTileSizeOptions() {
        return {
            includeSquare: this._config?.theme.dynamicProductGridIncludeSquare ?? true,
            includeHero: this._config?.theme.dynamicProductGridIncludeHero ?? true,
            includeTall: this._config?.theme.dynamicProductGridIncludeTall ?? true,
            includeWide: this._config?.theme.dynamicProductGridIncludeWide ?? true,
        };
    }

    public get builtInCustomAttributes(): KeyValuePair2[] {
        const kvps: KeyValuePair2[] = [];

        if (this.config()?.checkoutExperience === CheckoutExperience.Basket && this.upsellCategory) {
            kvps.push({
                key: 'CLOUDSHELF_UPSELL',
                value: 'true',
            });
        }

        return kvps;
    }

    public hasKnownPlugin(type: KnownPlugin) {
        const result = this.getKnownPlugin(type);

        if (result) {
            return true;
        }

        return false;
    }

    public getKnownPlugin(type: KnownPlugin) {
        return this.config()?.plugins.find(f => f.type === type);
    }

    public getKnownPluginVariable(type: KnownPlugin, key: string) {
        const plugin = this.getKnownPlugin(type);

        const v = plugin?.variables.find(x => x.key.toUpperCase() === key.toUpperCase());

        if (v) {
            const val = v.value;

            if (!val || val.trim() === '') {
                return null;
            }

            return val;
        }

        return null;
    }

    public checkoutFlow(): CheckoutFlow {
        if (!this._config?.checkoutFlowOptions) {
            console.log('checkoutFlowOptions not found / attempted use before config loaded');
            return EmptyConfig.checkoutFlowOptions;
        }

        console.log('checkoutFlow', this._config.checkoutFlowOptions);
        return this._config.checkoutFlowOptions;
    }

    public get shouldUseOnlineSearch(): boolean {
        if (!this._config?.useOnlineSearch) {
            return false;
        }

        const intAllCat = this.categories.find(f => f.isInternalAllCategory);
        if (intAllCat) {
            return false;
        }

        return true;
    }

    public get shouldMatchVariantColourImages(): boolean {
        if (!this._config) {
            return false;
        }

        const pdpBlockVariant = this._config.pdpBlocks.find(block => block.__typename === 'PDPVariantsBlock');

        if (!pdpBlockVariant) {
            return false;
        }

        return pdpBlockVariant.matchColourTypesToImages ?? false;
    }
}
