import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AnalyticsService } from '../../../services/Analytics/AnalyticsService';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { CheckoutView } from '../../../components/apps/InteractiveApp/components/Checkout/CheckoutView';
import { PurchaseCallback } from '../../../components/apps/InteractiveApp/components/Purchase/PurchaseCallback';
import { PurchaseView } from '../../../components/apps/InteractiveApp/components/Purchase/PurchaseView';
import {
    SlidingModal,
    SlidingModalOrigin,
} from '../../../components/apps/InteractiveApp/components/SlidingModal/SlidingModal';
import { SessionManagementService } from '../../../services/SessionManagementService/SessionManagementService';
import { SessionEventType } from '../../../services/SessionManagementService/SessionEvent';
import { BasketService } from '../../../services/BasketService/BasketService';
import { CheckoutService } from '../../../services/CheckoutService/CheckoutService';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import { ProductFilteringService } from '../../../services/ProductServices/ProductFilteringService';
import { Category } from '../../../services/CategoryService/entities/Category';
import { UpsellView } from '../../../components/apps/InteractiveApp/components/Upsell/UpsellView';
import { PurchaseSuccessView } from '../../../components/apps/InteractiveApp/components/Purchase/success/PurchaseSuccessView';
import useStateRef from 'react-usestateref';
import { EventsService } from '../../../services/EventsService/EventsService';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';
import { CheckoutFlowAcquisitionOption } from '../../../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { NewPurchaseView } from '../../../components/apps/InteractiveApp/components/Purchase/NewPurchaseView';
export enum PurchaseStep {
    NONE = 'none',
    UPSELL_IF_APPLICABLE = 'upsell_if_applicable',
    BASKET = 'basket',
    PAYMENT = 'payment',
    // PAYMENT_SUCCESS = 'payment_success',
}

export type ContinuePurchaseFlowCallback = (step: PurchaseStep, option?: CheckoutFlowAcquisitionOption) => void;

export interface PurchaseProps {
    continue: ContinuePurchaseFlowCallback;
    currentStep: PurchaseStep;
    checkoutAcquisitionOption: CheckoutFlowAcquisitionOption | undefined;
    // checkout: () => void;
    // purchase: PurchaseCallback;
}

const PurchaseContext = createContext<PurchaseProps | null>(null);

export const PurchaseProvider: FunctionalComponentWithChildren = ({ children }) => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const [checkoutAcquisitionOption, setCheckoutAcquisitionOption] = useState<
        CheckoutFlowAcquisitionOption | undefined
    >(undefined);
    // const analyticsService = useInjection<AnalyticsService>(DependencyType.AnalyticsService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    // const checkoutService = useInjection<CheckoutService>(DependencyType.CheckoutService);
    const [purchaseStep, setPurchaseStep] = useState<PurchaseStep>(PurchaseStep.NONE);
    // const [purchasingVisible, setPurchasingVisible] = useState(false);
    // const [checkoutVisible, setCheckoutVisible] = useState(false);
    const [upsellCategory, setUpsellCategory] = useState<Category | undefined>(undefined);
    const [avoidUpsellProducts, setAvoidUpsellProducts] = useState<string[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [allowOpenUpsell, setAllowOpenUpsell, allowOpenRef] = useStateRef(true);

    useEffect(() => {
        if (sessionManagementService) {
            const observer = sessionManagementService.observe().subscribe(event => {
                if (event.type === SessionEventType.Ended) {
                    eventsService.setOpenProduct(undefined);
                    setPurchaseStep(PurchaseStep.NONE);
                    setAllowOpenUpsell(true);
                    setCheckoutAcquisitionOption(undefined);

                    // setCheckoutVisible(false);
                    // setPurchasingVisible(false);
                }
            });

            return () => observer.unsubscribe();
        }
    }, [sessionManagementService]);

    useEffect(() => {
        const sub = eventsService.observeBasketPaneOpenRequest().subscribe(isOpen => {
            if (isOpen) {
                onContinue(PurchaseStep.BASKET);
            }
        });

        return () => {
            sub.unsubscribe();
        };
    }, [eventsService]);

    const onContinue = useCallback(
        async (step: PurchaseStep, option?: CheckoutFlowAcquisitionOption) => {
            if (option !== undefined) {
                setCheckoutAcquisitionOption(option);
            }
            //we need to do some extra work here if we are given the upsell step
            console.log('ALLOW UPSELL', allowOpenUpsell);
            if (step === PurchaseStep.UPSELL_IF_APPLICABLE) {
                if (!allowOpenRef.current) {
                    setPurchaseStep(PurchaseStep.BASKET);
                    return;
                }
                const upsellCat = configService.upsellCategory;

                if (!upsellCat) {
                    //If theres no upsell category, we can just go to the next step
                    setPurchaseStep(PurchaseStep.BASKET);
                    return;
                } else {
                    //if there is an upsell category, we need to see if there are any products in the category that we DONT already have in the basket.
                    //If there are, we need to show the upsell step, if not, we can go straight to the next step

                    const allProductHandlesInBasket =
                        basketService.basket?.lineItems.map(item => item.product.handle) || [];

                    const productsInCategory = await filteringService.matchingProducts(
                        'Checking if we should upsell',
                        upsellCat,
                        [],
                        { limit: 30 },
                        false,
                        allProductHandlesInBasket,
                    );

                    if (productsInCategory.items.length === 0) {
                        setPurchaseStep(PurchaseStep.BASKET);
                        return;
                    }
                    setUpsellCategory(upsellCat!);
                    setAvoidUpsellProducts(allProductHandlesInBasket);
                    setPurchaseStep(PurchaseStep.UPSELL_IF_APPLICABLE);
                    return;
                }
            } else {
                setPurchaseStep(step);
                return;
            }
        },
        [setPurchaseStep, setUpsellCategory, setAvoidUpsellProducts, configService, filteringService, allowOpenUpsell],
    );

    return (
        // <PurchaseContext.Provider value={{ purchase: onPurchase, continue: onCheckout }}>
        <PurchaseContext.Provider
            value={{ continue: onContinue, currentStep: purchaseStep, checkoutAcquisitionOption }}
        >
            {/*<SlidingModal isOpen={checkoutVisible} origin={SlidingModalOrigin.BOTTOM} fullWidth fullHeight>*/}
            {/*    <CheckoutView*/}
            {/*        isOpen={checkoutVisible}*/}
            {/*        onClose={onCheckoutClosed}*/}
            {/*        setLoading={setLoading}*/}
            {/*        onPurchase={onPurchase}*/}
            {/*        loading={loading}*/}
            {/*    />*/}
            {/*</SlidingModal>*/}
            {/*<SlidingModal*/}
            {/*    origin={SlidingModalOrigin.BOTTOM}*/}
            {/*    rounded*/}
            {/*    isOpen={purchasingVisible}*/}
            {/*    transparent={!purchasingVisible}*/}
            {/*    fullWidth*/}
            {/*>*/}
            {/*    {purchasingVisible && <PurchaseView onCloseButtonClicked={onPurchaseClosed} />}*/}
            {/*</SlidingModal>*/}

            <SlidingModal
                isOpen={purchaseStep === PurchaseStep.BASKET}
                origin={SlidingModalOrigin.BOTTOM}
                fullWidth
                fullHeight
            >
                <CheckoutView
                    isOpen={purchaseStep === PurchaseStep.BASKET}
                    onClose={() => setPurchaseStep(PurchaseStep.NONE)}
                    setLoading={setLoading}
                    onContinue={onContinue}
                    loading={loading}
                />
            </SlidingModal>

            <SlidingModal
                isOpen={purchaseStep === PurchaseStep.UPSELL_IF_APPLICABLE}
                origin={SlidingModalOrigin.TOP}
                onBackgroundClicked={() => {}}
                noBackgroundDarking
            >
                <UpsellView
                    isOpen={purchaseStep === PurchaseStep.UPSELL_IF_APPLICABLE}
                    onClose={() => {
                        setAllowOpenUpsell(false);
                        setPurchaseStep(PurchaseStep.NONE);
                    }}
                    onContinue={onContinue}
                    category={upsellCategory!}
                    avoidProducts={avoidUpsellProducts}
                />
            </SlidingModal>

            <SlidingModal
                origin={SlidingModalOrigin.BOTTOM}
                isOpen={purchaseStep === PurchaseStep.PAYMENT}
                transparent={!(purchaseStep === PurchaseStep.PAYMENT)}
                fullWidth
                fullHeight
            >
                {purchaseStep === PurchaseStep.PAYMENT && (
                    // <PurchaseView onCloseButtonClicked={() => setPurchaseStep(PurchaseStep.NONE)} />
                    <NewPurchaseView onCloseButtonClicked={() => setPurchaseStep(PurchaseStep.NONE)} />
                )}
            </SlidingModal>

            {/* <SlidingModal
                origin={SlidingModalOrigin.BOTTOM}
                isOpen={purchaseStep === PurchaseStep.PAYMENT_SUCCESS}
                // isOpen
                // isOpen={purchaseStep === PurchaseStep.PAYMENT_SUCCESS}
                // transparent={!(purchaseStep === PurchaseStep.PAYMENT_SUCCESS)}
                fullWidth
                fullHeight
            >
                <PurchaseSuccessView />
            </SlidingModal> */}

            {children}
        </PurchaseContext.Provider>
    );
};

export const usePurchase = (): PurchaseProps => {
    const purchaseContext = useContext(PurchaseContext);
    if (purchaseContext === null) {
        throw new Error('Are you accessing the purchase context outside of its children?');
    }
    return purchaseContext;
};
